interface ListItem {
	name: string;
	link: string;
}

interface ExploreSection {
	title: string;
	text: string;
	lists?: ListItem[];
	all?: string;
}

interface StudyDatabaseSection {
	title: string;
	text: string;
}

interface ResearchSection {
	title: string;
	text: string;
}

export interface SupplementCategory {
	work?: string[];
	mayWork?: string[];
	avoid?: string[];
}

interface GuideSection {
	title: string;
	free: boolean;
	supplements: SupplementCategory[];
}

interface LatestScientificSection {
	title: string;
}

interface Tweet {
	name: string;
	position: string;
	comment: string;
}

interface Home {
	explores: ExploreSection[];
	studyDatabase: StudyDatabaseSection[];
	research: ResearchSection[];
	guides: GuideSection[];
	latestScientific: LatestScientificSection[];
	twitters: Tweet[];
}

export const home: Home = {
	explores: [
		{
			title: "Conditions",
			text: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
			lists: [
				{
					name: "Anxiety",
					link: "/conditions/anxiety",
				},
				{
					name: "Depression",
					link: "/conditions/depression",
				},
				{
					name: "Intestinal candidiasis",
					link: "/conditions/intestinal-candidiasis/",
				},
				{
					name: "Insomnia",
					link: "/conditions/insomnia",
				},
				{
					name: "Low testosterone",
					link: "/conditions/low-testosterone",
				},
			],
			all: "/conditions",
		},
		{
			title: "Supplements & More",
			text: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
			lists: [
				{
					name: "BPC-157",
					link: "/supplements/bpc-157/",
				},
				{
					name: "Creatine",
					link: "/supplements/creatine/",
				},
				{
					name: "Ashwagandha",
					link: "/supplements/ashwagandha/",
				},
				{
					name: "Alpha-GPC",
					link: "/supplements/alpha-gpc/",
				},
				{
					name: "Berberine",
					link: "/supplements/berberine/",
				},
			],
			all: "/interventions/",
		},
		{
			title: "Guides",
			text: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
			lists: [
				{
					name: "Optimal protein intake",
					link: "/guides/protein-intake/",
				},
				{
					name: "How to Read a Study",
					link: "/guides/how-to-read-a-study/",
				},
				{
					name: "Memory and Focus",
					link: "/guides/memory-focus/",
				},
				{
					name: "Muscle Gain",
					link: "/guides/muscle-gain/",
				},
				{
					name: "Fat Loss",
					link: "/guides/fat-loss/",
				},
			],
			all: "/guides",
		},
		// {
		// 	title: "Outcomes",
		// 	text: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
		// 	lists: [
		// 		{
		// 			name: "Blood Glucose",
		// 			link: "outcomes/blood-glucose",
		// 		},
		// 		{
		// 			name: "Blood Pressure",
		// 			link: "outcomes/blood-pressure",
		// 		},
		// 		{
		// 			name: "Testosterone",
		// 			link: "outcomes/testosterone",
		// 		},
		// 	],
		// 	all: "/outcomes",
		// },
		{
			title: "Categories",
			text: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.",
			lists: [
				{
					name: "Brain Health",
					link: "/categories/brain-health",
				},
				{
					name: "Fat Loss",
					link: "/categories/fat-loss",
				},
				{
					name: "Muscle Gain & Exercise",
					link: "/categories/muscle-gain-exercise",
				},
				{
					name: "Gut Health",
					link: "/categories/gut-health",
				},
				{
					name: "Sleep",
					link: "/categories/sleep",
				},
			],
			all: "/categories",
		},
	],
	studyDatabase: [
		{
			title: "Conditions",
			text: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
		},
		{
			title: "Interventions",
			text: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
		},
		{
			title: "Outcomes",
			text: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
		},
		{
			title: "Effect",
			text: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
		},
	],
	research: [
		{
			title: "Thorough",
			text: "Our research team pours through hundreds of studies and clinical trials to bring you concise health information",
		},
		{
			title: "Evidence-based",
			text: "We cite our sources, and allow you to delve as deep as we do",
		},
		{
			title: "Independent",
			text: "We don’t accept donors, sponsors, advertisements, or paid afiliations",
		},
		{
			title: "Transparent",
			text: "We keep our users updated when mistakes are identified as a result of our constant revision.",
		},
	],
	guides: [
		{
			title: "Allergies & Immunity",
			free: false,
			supplements: [
				{
					work: ["Vitamin A", "?", "?"],
				},
				{
					mayWork: ["Coconut oil", "Polypodium leucotomos", "?", "?", "?", "?"],
				},
				{
					avoid: ["Tinospora cordifolia", "?"],
				},
			],
		},
		{
			title: "Bone Health",
			free: false,
			supplements: [
				{
					work: ["Vitamin D", "Vitamin K", "?", "?", "?"],
				},
				{
					mayWork: ["Coral calcium", "?"],
				},
			],
		},
		{
			title: "Cardiovascular Health",
			free: false,
			supplements: [
				{
					work: ["Cocoa", "CoQ10", "Garlic"],
				},
				{
					mayWork: ["Pine bark", "?", "?"],
				},
				{
					avoid: ["Stimulants", "?"],
				},
			],
		},
		{
			title: "Diabetes & Blood Sugar",
			free: false,
			supplements: [
				{
					work: ["Zinc", "?", "?", "?", "?"],
				},
				{
					mayWork: ["Cinnamon", "?", "?"],
				},
				{
					avoid: ["Corosolic acid", "Vitamin C", "?", "?", "?", "?", "?", "?", "?", "?"],
				},
			],
		},
		{
			title: "Fat Loss",
			free: false,
			supplements: [
				{
					work: ["Protein", "EGCg", "?", "?", "?"],
				},
				{
					mayWork: ["Fiber", "MCTS", "?", "?", "?", "?"],
				},
				{
					avoid: ["Berberine", "Raspberry ketone", "?", "?", "?", "?"],
				},
			],
		},
		{
			title: "Healthy Aging",
			free: true,
			supplements: [
				{
					work: ["Protein", "Vitamin B", "?", "?", "?", "?", "?", "?", "?", "?"],
				},
				{
					mayWork: ["Vitamin E"],
				},
				{
					avoid: ["Vitamin B (folic acid)", "?", "?", "?", "?"],
				},
			],
		},
		{
			title: "Joint Health",
			free: false,
			supplements: [
				{
					work: ["Boswellia serrata", "Curcumin", "?", "?", "?", "?"],
				},
				{
					mayWork: ["MSM", "?", "?", "?"],
				},
				{
					avoid: ["CBD (cannabidiol)", "?"],
				},
			],
		},
		{
			title: "Libido & Sexual Function",
			free: false,
			supplements: [
				{
					work: ["Maca", "?", "?", "?"],
				},
				{
					mayWork: ["Cocoa", "Fenugreek", "?", "?"],
				},
				{
					avoid: ["Libido enhancers", "Testosterone boosters"],
				},
			],
		},
		{
			title: "Liver Health",
			free: false,
			supplements: [
				{
					work: ["Milk thistle", "TUDCA", "?", "?"],
				},
				{
					mayWork: ["Spirulina", "?", "?"],
				},
				{
					avoid: ["Alcohol"],
				},
			],
		},
		{
			title: "Memory & Focus",
			free: false,
			supplements: [
				{
					work: ["Caffeine + Theanine", "?", "?"],
				},
				{
					mayWork: ["Creatine", "Uridine", "?", "?", "?"],
				},
				{
					avoid: ["Nicotine", "?"],
				},
			],
		},
		{
			title: "Mood & Depression",
			free: false,
			supplements: [
				{
					work: ["St. John's Wort", "Zinc", "?", "?", "?", "?"],
				},
				{
					mayWork: ["NAC", "?"],
				},
				{
					avoid: ["Psychostimulants", "?"],
				},
			],
		},
		{
			title: "Muscle Gain & Exercise Performance",
			free: false,
			supplements: [
				{
					work: ["Creatine", "Nitrates", "?", "?", "?", "?"],
				},
				{
					mayWork: ["HMB", "?", "?", "?", "?", "?", "?"],
				},
				{
					avoid: ["Glutamine", "?"],
				},
			],
		},
		{
			title: "Skin, Hair & Nails",
			free: false,
			supplements: [
				{
					work: ["Vitamin A", "?", "?"],
				},
				{
					mayWork: ["Coconut oil", "Polypodium leucotomos", "?", "?"],
				},
				{
					avoid: [
						"Creams with vitamin E",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
					],
				},
			],
		},
		{
			title: "Sleep",
			free: false,
			supplements: [
				{
					work: ["Magnesium", "?", "?"],
				},
				{
					mayWork: ["Lemon balm ", "?", "?"],
				},
				{
					avoid: ["Caffeine"],
				},
			],
		},
		{
			title: "Stress & Anxiety",
			free: false,
			supplements: [
				{
					work: ["Lavender", "?", "?", "?", "?"],
				},
				{
					mayWork: ["Ashwagandha", "?", "?", "?", "?"],
				},
				{
					avoid: ["Yohimbine", "?"],
				},
			],
		},

		{
			title: "Testosterone",
			free: false,
			supplements: [
				{
					work: ["DHEA", "?", "?", "?"],
				},
				{
					mayWork: ["Boron"],
				},
				{
					avoid: ["Libido enhancers", "Testosterone boosters", "?", "?", "?", "?"],
				},
			],
		},
		{
			title: "Vegetarians & Vegans",
			free: false,
			supplements: [
				{
					work: ["Iron + Vit. C", "?", "?", "?", "?", "?", "?", "?"],
				},
				{
					mayWork: ["Beta-alanine"],
				},
				{
					avoid: ["Omega 3-6-9"],
				},
			],
		},
	],
	latestScientific: [
		{
			title: "Allergies & Immunity",
		},
		{
			title: "Bone Health",
		},
		{
			title: "Cardiovascular Health",
		},
		{
			title: "Men's Health",
		},
		{
			title: "Fat Loss",
		},
	],
	twitters: [
		{
			name: "Aaron Almaraz",
			position: "CEO & Founder at Gearat",
			comment:
				"Time is the most precious thing you have when bootstrapping. You can't take time to ponder on design…",
		},
		{
			name: "Phet Putrie",
			position: "Freelancer",
			comment:
				"Time is the most precious thing you have when bootstrapping. You can't take time to ponder on design…",
		},
		{
			name: "Lara Madrigal",
			position: "Marketing Specialist",
			comment:
				"Time is the most precious thing you have when bootstrapping. You can't take time to ponder on design…",
		},
		{
			name: "Marleah Eagleston",
			position: "Founder at Spicenet",
			comment:
				"Time is the most precious thing you have when bootstrapping. You can't take time to ponder on design…",
		},
		{
			name: "Prescott MacCaffery",
			position: "Writer",
			comment:
				"Time is the most precious thing you have when bootstrapping. You can't take time to ponder on design…",
		},
		{
			name: "Waiapi Karaka",
			position: "Designer, Freelancer",
			comment:
				"Time is the most precious thing you have when bootstrapping. You can't take time to ponder on design…",
		},
	],
};
