"use client";

import Link from "next/link";
import { ArrowRight } from "lucide-react";

import { cn } from "@/lib/utils";
import { percentOff, salesType } from "@/lib/data/sales";
import { FreeTrialWrapper } from "@/components/free-trial-wrapper";
import SaleWrapper from "@/components/sale-wrapper";
import { ExaminePlusLink } from "@/components/cta";
import { Badge } from "@/components/ui/badge";
import { buttonVariants } from "@/components/ui/button";

const ExaminePlusCTA = () => {
	return (
		<SaleWrapper
			monthlyPaidCta={
				<div className="mt-5 space-y-4 bg-examine-purple-100 px-6 py-5 text-start text-gray-900">
					<div className="flex flex-col items-center justify-center gap-4 py-2 xl:flex-row">
						<h2 className="text-center font-semibold leading-snug">
							<span className="inline font-lora">Examine’s 72-hour {salesType} is on!</span>
							<span className="inline-block">
								<ExaminePlusLink
									trackDetails={{
										trackFor: "sale",
										label: "Unlock savings",
										location: "Below Search on Homepage ~ Monthly Paid",
									}}
								>
									<Badge
										variant="cyan"
										className="ml-3 mt-2 whitespace-nowrap rounded-full bg-examine-green-500 px-2 py-1 align-middle  font-sans text-xs font-semibold text-white hover:bg-primary sm:text-sm lg:py-1.5"
									>
										Unlock savings
									</Badge>
								</ExaminePlusLink>
							</span>
						</h2>
					</div>
					<div className="space-y-2 sm:mx-4">
						<p className="mx-auto pb-2 text-center text-sm lg:text-base xl:text-lg xl:leading-8">
							Thank you for being a member! We hope you’re enjoying the benefits of your Examine+
							membership, from staying on top of the latest research to the peace of mind that comes
							from knowing which interventions to try (and avoid) while quickly finding relevant
							information for all your health goals.
						</p>
						<p className="mx-auto pb-2 text-center text-sm lg:text-base xl:text-lg xl:leading-8">
							Plus, more member-only features are coming soon, perfect for enthusiasts,
							professionals, and anyone who needs easy answers to their health questions:
						</p>
						<ul className="mx-auto list-inside list-disc space-y-1.5 pb-4 pl-6 text-center text-sm sm:pl-8 lg:text-base xl:text-lg xl:leading-8">
							<li>More options to personalize your use of Examine</li>
							<li>In-depth safety, interaction, and dosage information</li>
							<li>
								Access to ExamineAI, our upcoming chatbot trained exclusively{" "}
								<br className="hidden xl:block" />
								on evidence-based Examine content
							</li>
						</ul>
						<p className="mx-auto pb-2 text-center text-sm lg:text-base xl:text-lg xl:leading-8">
							<strong>
								Until August 15, get a{" "}
								<Link href="/promotion/yearlyupgrade?loc=checkout/jplqpJ6" className="link">
									Yearly membership for $144/year ($12/month)
								</Link>{" "}
								or{" "}
								<Link href="/promotion/mlifetimeupgrade?loc=checkout/OEJ3plv" className="link">
									lock in 38% off Lifetime.
								</Link>
							</strong>{" "}
							<span className="relative inline-block before:absolute before:bottom-0.5 before:left-0 before:z-0 before:w-full before:bg-premium/40 before:pb-[2%] sm:before:pb-[3%]">
								<span className="relative whitespace-nowrap">
									You’ll get a prorated refund when you uprade.
								</span>
							</span>
						</p>
						<div className="inline-flex w-full px-6 pt-4 text-center">
							<ExaminePlusLink
								trackDetails={{
									trackFor: "sale",
									label: "Upgrade your membership and save up to 38%",
									location: "Below Search on Homepage ~ Monthly Paid",
								}}
								className={cn(
									buttonVariants(),
									"mx-auto inline-flex font-semibold lg:px-4 lg:py-2 lg:text-xl"
								)}
							>
								<span>
									Upgrade your membership and save up to 38%{" "}
									<ArrowRight size={24} className="inline" />
								</span>
							</ExaminePlusLink>
						</div>
					</div>
				</div>
			}
			yearlyPaidCta={
				<div className="mt-5 space-y-4 bg-examine-purple-100 px-6 py-5 text-start text-gray-900">
					<div className="flex flex-col items-center justify-center gap-4 py-2 xl:flex-row">
						<h2 className="text-center font-semibold leading-snug">
							<span className="inline font-lora">Examine’s 72-hour {salesType} is on!</span>
							<span className="inline-block">
								<ExaminePlusLink
									trackDetails={{
										trackFor: "sale",
										label: "Unlock savings",
										location: "Below Search on Homepage ~ Yearly Paid",
									}}
								>
									<Badge
										variant="cyan"
										className="ml-3 mt-2 whitespace-nowrap rounded-full bg-examine-green-500 px-2 py-1 align-middle  font-sans text-xs font-semibold text-white hover:bg-primary sm:text-sm lg:py-1.5"
									>
										Unlock savings
									</Badge>
								</ExaminePlusLink>
							</span>
						</h2>
					</div>
					<div className="space-y-2 sm:mx-4">
						<p className="mx-auto pb-2 text-center text-sm lg:text-base xl:text-lg xl:leading-8">
							Thank you for being a member! We hope you’re enjoying the benefits of your Examine+
							membership, from staying on top of the latest research to the peace of mind that comes
							from knowing which interventions to try (and avoid) while quickly finding relevant
							information for all your health goals.
						</p>
						<p className="mx-auto pb-2 text-center text-sm lg:text-base xl:text-lg xl:leading-8">
							Plus, more member-only features are coming soon, perfect for enthusiasts,
							professionals, and anyone who needs easy answers to their health questions:
						</p>
						<ul className="mx-auto list-inside list-disc space-y-1.5 pb-4 pl-6 text-center text-sm sm:pl-8 lg:text-base xl:text-lg xl:leading-8">
							<li>More options to personalize your use of Examine</li>
							<li>In-depth safety, interaction, and dosage information</li>
							<li>
								Access to ExamineAI, our upcoming chatbot trained exclusively{" "}
								<br className="hidden xl:block" />
								on evidence-based Examine content
							</li>
						</ul>
						<p className="mx-auto pb-2 text-center text-sm lg:text-base xl:text-lg xl:leading-8">
							<strong>
								Until August 15, get a{" "}
								<Link href="/promotion/mlifetimeupgrade?loc=checkout/OEJ3plv/" className="link">
									Lifetime membership for $499 (38% off)
								</Link>{" "}
								and get Examine+ access for life
							</strong>
							.{" "}
							<span className="relative inline-block before:absolute before:bottom-0.5 before:left-0 before:z-0 before:w-full before:bg-premium/40 before:pb-[2%] sm:before:pb-[3%]">
								<span className="relative whitespace-nowrap">
									You’ll get a prorated refund when you uprade.
								</span>
							</span>
						</p>
						<div className="inline-flex w-full px-6 pt-4 text-center">
							<Link
								href="/promotion/ylifetimeupgrade?loc=checkout/OEJ3plv/"
								className={cn(
									buttonVariants(),
									"mx-auto inline-flex font-semibold lg:px-4 lg:py-2 lg:text-xl"
								)}
							>
								<span>
									Get Examine+ Lifetime for $499 (38% off)
									<ArrowRight size={24} className="inline" />
								</span>
							</Link>
						</div>
					</div>
				</div>
			}
			cta={
				<FreeTrialWrapper>
					<div className="pt-8">
						<Link
							href="/plus"
							className={cn(
								buttonVariants({ variant: "primary" }),
								"inline-flex flex-col rounded-xl px-6 py-4 lg:text-xl"
							)}
							onClick={() => {
								window.dataLayer?.push({
									event: "homepage_hero_cta_click",
								});
							}}
						>
							<span>
								<strong>Examine+</strong> unlocks everything on the site
								<br className="hidden sm:block" /> Try free for 7 days{" "}
								<ArrowRight size={24} className="inline" />
							</span>
						</Link>
					</div>
				</FreeTrialWrapper>
			}
		>
			<div className="pt-8">
				<ExaminePlusLink
					className={cn(
						buttonVariants({ variant: "primary" }),
						"inline-flex flex-col rounded-xl px-6 py-4 lg:text-xl"
					)}
					trackDetails={{
						trackFor: "sale",
						label: `Examine+ unlocks everything on the site On sale for over ${percentOff} off! Start now`,
						location: "Below Search on Homepage ~ Free",
					}}
				>
					<span className="">
						<strong>Examine+</strong> unlocks everything on the site
						<br className="hidden sm:block" /> On sale for over {percentOff} off!
						<br /> Start now
						<ArrowRight size={24} className="inline" />
					</span>
				</ExaminePlusLink>
			</div>
		</SaleWrapper>
	);
};

export { ExaminePlusCTA };
