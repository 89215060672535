"use client";

import Link from "next/link";
import { ArrowRight, PlusIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import { percentOff } from "@/lib/data/sales";
import { useExpandCollapse } from "@/lib/hooks/use-expand-collapse";
import ResearchCard from "@/components/research-card";
import { ExaminePlusLink } from "@/components/cta";
import { Alert } from "@/components/ui/alert";
import { AccessCheck } from "@/components/access-check";
import { buttonVariants } from "@/components/ui/button";
import SaleWrapper from "@/components/sale-wrapper";
import { FreeTrialWrapper } from "@/components/free-trial-wrapper";
import { SummaryDetail, User } from "types";

export default function ResearchFeedPreview({
	summaries,
	user,
}: {
	summaries: SummaryDetail[];
	user: User;
}) {
	const cardIds = summaries?.length ? summaries.map((summary: { id: any }) => summary?.id) : [];
	const { setCardExpanded, isCardExpanded } = useExpandCollapse(cardIds);

	return (
		<div className="bg-white py-10 md:pt-15 xl:pt-20">
			<div className="flex w-full flex-wrap items-center justify-center gap-3 px-6 text-center">
				<h2 className="font-lora font-medium text-gray-900 lg:text-2xl xl:text-4xl">
					Your Research Feed
				</h2>
				<AccessCheck
					loggedIn
					cta={
						<Link
							className={cn("", buttonVariants({ variant: "outline", size: "sm" }))}
							href="/research-feed/?customize=1"
						>
							Customize
						</Link>
					}
				/>
			</div>
			<AccessCheck
				feature={["study_summaries"]}
				cta={
					<>
						<div className="relative mx-auto flex w-full justify-center p-6 pt-8 text-center md:p-10">
							<ul className="max-w-5xl">
								{summaries?.slice(0, 5)?.map((summary: any) => {
									const rules = {
										showContent: true,
										showQuickSummary: true,
										isSecondSummary: true,
										showActionbar: true,
										isResearchFeed: true,
										isUser: true,
										hasAccess: true,
										view: "simple",
										setExpanded: (expanded: boolean) => setCardExpanded(summary.id, expanded),
										isExpanded: isCardExpanded(summary.id),
									};

									return (
										<li className="text-start" key={summary.id}>
											<ResearchCard key={summary.id} summary={summary} rules={rules} />
										</li>
									);
								})}
							</ul>
							{summaries?.length === 0 && (
								<div className="flex w-full flex-col items-center justify-center gap-y-8 py-6">
									<Link
										href="/members/dashboard/?customize=1"
										className={cn(
											buttonVariants({ variant: "link" }),
											"justify-center text-center text-primary"
										)}
									>
										<PlusIcon className="-mt-1 inline size-3 xl:size-3.5" /> Add categories
									</Link>

									<Link
										href="/research-feed/"
										className={cn(
											"justify-center text-center text-primary",
											buttonVariants({ variant: "link" })
										)}
									>
										See all of the latest study summaries
									</Link>
								</div>
							)}
						</div>
						<div className="inline-flex w-full px-6 text-center">
							<Link
								href="/research-feed/"
								className={cn(
									buttonVariants(),
									"mx-auto inline-flex font-semibold lg:px-4 lg:py-2 lg:text-xl"
								)}
							>
								<span>
									Go to your research feed <ArrowRight size={16} className="inline lg:stroke-4" />
								</span>
							</Link>
						</div>
					</>
				}
			>
				<Alert variant="info" className="mx-4 my-6 max-w-3xl text-sm md:mx-auto lg:text-lg">
					Examine+ subscribers get access to{" "}
					<Link
						href="/research-feed/"
						className="font-bold text-examine-purple-400 hover:underline"
					>
						summaries of the latest research
					</Link>{" "}
					— seven new studies every business day.{" "}
					<SaleWrapper
						cta={
							<FreeTrialWrapper>
								<ExaminePlusLink
									trackDetails={{
										trackFor: "trial",
										location: "above page overview section",
										label: "Try Examine+ free for 7 days",
										ad_element: "Info blockquote",
									}}
									className="font-bold text-examine-purple-400 hover:underline"
								>
									Try free for 7 days <ArrowRight className="inline size-4 stroke-[2px]" />
								</ExaminePlusLink>
							</FreeTrialWrapper>
						}
					>
						<ExaminePlusLink
							trackDetails={{
								trackFor: "sale",
								location: "Research Feed Preview on HomePage",
								label: `Get Examine+ for ${percentOff} off`,
							}}
							className="font-bold text-examine-purple-400 hover:underline"
						>
							Get Examine+ for {percentOff} off{" "}
							<ArrowRight className="inline size-4 stroke-[2px]" />
						</ExaminePlusLink>
					</SaleWrapper>
				</Alert>
			</AccessCheck>
		</div>
	);
}
